export const questionData = {
    "data": [
        {
            "id": 4,
            "options": [
                {
                    "id": "4",
                    "text": "restrained"
                },
                {
                    "id": "67",
                    "text": "decisive"
                },
                {
                    "id": "101",
                    "text": "chatty"
                },
                {
                    "id": "136",
                    "text": "traditional"
                }
            ]
        },
        {
            "id": 10,
            "options": [
                {
                    "id": "10",
                    "text": "fearless"
                },
                {
                    "id": "73",
                    "text": "inspiring"
                },
                {
                    "id": "107",
                    "text": "supporting"
                },
                {
                    "id": "142",
                    "text": "reserved"
                }
            ]
        },
        {
            "id": 14,
            "options": [
                {
                    "id": "14",
                    "text": "excitable"
                },
                {
                    "id": "77",
                    "text": "firm"
                },
                {
                    "id": "111",
                    "text": "obedient"
                },
                {
                    "id": "145",
                    "text": "critical"
                }
            ]
        },
        {
            "id": 19,
            "options": [
                {
                    "id": "19",
                    "text": "agreeable"
                },
                {
                    "id": "82",
                    "text": "hesitant"
                },
                {
                    "id": "116",
                    "text": "extroverted"
                },
                {
                    "id": "150",
                    "text": "blunt"
                }
            ]
        },
        {
            "id": 103,
            "options": [
                {
                    "id": "3",
                    "text": "calm"
                },
                {
                    "id": "66",
                    "text": "outspoken"
                },
                {
                    "id": "100",
                    "text": "precise"
                },
                {
                    "id": "160",
                    "text": "sociable"
                }
            ]
        },
        {
            "id": 106,
            "options": [
                {
                    "id": "29",
                    "text": "light-hearted"
                },
                {
                    "id": "69",
                    "text": "soft-spoken"
                },
                {
                    "id": "126",
                    "text": "systematic"
                },
                {
                    "id": "138",
                    "text": "persuasive"
                }
            ]
        },
        {
            "id": 107,
            "options": [
                {
                    "id": "7",
                    "text": "dominant"
                },
                {
                    "id": "70",
                    "text": "expressive"
                },
                {
                    "id": "127",
                    "text": "accepting"
                },
                {
                    "id": "139",
                    "text": "meticulous"
                }
            ]
        },
        {
            "id": 108,
            "options": [
                {
                    "id": "8",
                    "text": "entertaining"
                },
                {
                    "id": "71",
                    "text": "modest"
                },
                {
                    "id": "128",
                    "text": "serious"
                },
                {
                    "id": "140",
                    "text": "restless"
                }
            ]
        },
        {
            "id": 109,
            "options": [
                {
                    "id": "9",
                    "text": "forceful"
                },
                {
                    "id": "92",
                    "text": "private"
                },
                {
                    "id": "106",
                    "text": "accommodating"
                },
                {
                    "id": "141",
                    "text": "magnetic"
                }
            ]
        },
        {
            "id": 112,
            "options": [
                {
                    "id": "12",
                    "text": "independent"
                },
                {
                    "id": "93",
                    "text": "analytical"
                },
                {
                    "id": "109",
                    "text": "kind"
                },
                {
                    "id": "144",
                    "text": "dynamic"
                }
            ]
        },
        {
            "id": 113,
            "options": [
                {
                    "id": "30",
                    "text": "prudent"
                },
                {
                    "id": "76",
                    "text": "compassionate"
                },
                {
                    "id": "110",
                    "text": "competitive"
                },
                {
                    "id": "143",
                    "text": "cheerful"
                }
            ]
        },
        {
            "id": 115,
            "options": [
                {
                    "id": "31",
                    "text": "even-tempered"
                },
                {
                    "id": "78",
                    "text": "stubborn"
                },
                {
                    "id": "112",
                    "text": "introverted"
                },
                {
                    "id": "146",
                    "text": "charming"
                }
            ]
        },
        {
            "id": 118,
            "options": [
                {
                    "id": "18",
                    "text": "detail-oriented"
                },
                {
                    "id": "81",
                    "text": "impatient"
                },
                {
                    "id": "130",
                    "text": "lenient"
                },
                {
                    "id": "149",
                    "text": "lively"
                }
            ]
        },
        {
            "id": 120,
            "options": [
                {
                    "id": "20",
                    "text": "straight-shooter"
                },
                {
                    "id": "83",
                    "text": "objective"
                },
                {
                    "id": "117",
                    "text": "empathetic"
                },
                {
                    "id": "163",
                    "text": "playful"
                }
            ]
        },
        {
            "id": 121,
            "options": [
                {
                    "id": "33",
                    "text": "open"
                },
                {
                    "id": "94",
                    "text": "gentle"
                },
                {
                    "id": "118",
                    "text": "disciplined"
                },
                {
                    "id": "164",
                    "text": "tough"
                }
            ]
        },
        {
            "id": 122,
            "options": [
                {
                    "id": "22",
                    "text": "warm"
                },
                {
                    "id": "85",
                    "text": "methodical"
                },
                {
                    "id": "131",
                    "text": "enthusiastic"
                },
                {
                    "id": "153",
                    "text": "vigorous"
                }
            ]
        },
        {
            "id": 123,
            "options": [
                {
                    "id": "23",
                    "text": "intense"
                },
                {
                    "id": "95",
                    "text": "perfectionist"
                },
                {
                    "id": "120",
                    "text": "gregarious"
                },
                {
                    "id": "154",
                    "text": "sympathetic"
                }
            ]
        },
        {
            "id": 126,
            "options": [
                {
                    "id": "26",
                    "text": "direct"
                },
                {
                    "id": "89",
                    "text": "accurate"
                },
                {
                    "id": "123",
                    "text": "joyful"
                },
                {
                    "id": "165",
                    "text": "practical"
                }
            ]
        },
        {
            "id": 128,
            "options": [
                {
                    "id": "28",
                    "text": "persistent"
                },
                {
                    "id": "27",
                    "text": "cautious"
                },
                {
                    "id": "125",
                    "text": "helpful"
                },
                {
                    "id": "159",
                    "text": "optimistic"
                }
            ]
        }
    ]
};

export const translations = {
    "welcome_message": "Оберіть слово, яке характеризує вас найбільше, та, аналогічно, найменше. Після завершення тесту ви, можливо, дізнаєтесь дещо цікаве про себе",
    "excitable": {
        "key_translation": "збудливий",
        "value_translation": "швидко реагує на зовнішні подразники, та може легко стати збудженим або схвильованим"
    },
    "accepting": {
        "key_translation": "толерантний",
        "value_translation": "визнання та повага до вірувань чи стану інших"
    },
    "accommodating": {
        "key_translation": "поступливий",
        "value_translation": "відповідає чиїмось бажанням або вимогам у корисний спосіб"
    },
    "accurate": {
        "key_translation": "точний",
        "value_translation": "здатний або успішний в досягненні запланованої мети"
    },
    "adventurous": {
        "key_translation": "авантюрний",
        "value_translation": "готовий ризикувати або спробувати щось нове, нестандартне, екстремальне"
    },
    "aggressive": {
        "key_translation": "агресивний",
        "value_translation": "схильний атакувати чи конфронтувати"
    },
    "agreeable": {
        "key_translation": "приємний",
        "value_translation": "приємний та задовільний"
    },
    "aloof": {
        "key_translation": "байдужий",
        "value_translation": "холодний та відсторонений"
    },
    "altruistic": {
        "key_translation": "альтруїст",
        "value_translation": "проявляє безкорисливу турботу про добробут інших"
    },
    "analytical": {
        "key_translation": "аналітичний",
        "value_translation": "використовує аналіз або логічне мислення"
    },
    "assertive": {
        "key_translation": "ствердний",
        "value_translation": "мати впевнену та наполегливу особистість"
    },
    "available": {
        "key_translation": "доступний",
        "value_translation": "не зайнятий і готовий зробити щось"
    },
    "blunt": {
        "key_translation": "грубуватий",
        "value_translation": "прямий і відвертий; прямолінійний і чесний",
    },
    "bold": {
        "key_translation": "сміливий",
        "value_translation": "показує здатність ризикувати, має впевненість та сміливість"
    },
    "calculated": {
        "key_translation": "все прораховую",
        "value_translation": "обережне планування дій наперед"
    },
    "calm": {
        "key_translation": "спокійний",
        "value_translation": "не проявляє чи не відчуває нервозності, гніву чи інших емоцій"
    },
    "careful": {
        "key_translation": "обережний",
        "value_translation": "робить все задля уникнення можливої небезпеки, нещастя чи шкоди; обачний"
    },
    "cautious": {
        "key_translation": "обачний",
        "value_translation": "уважний до можливих проблем або небезпек"
    },
    "charismatic": {
        "key_translation": "харизматичний",
        "value_translation": "володіє привабливою харизмою, яка надихає інших"
    },
    "charming": {
        "key_translation": "чарівний",
        "value_translation": "приносить задоволення або викликає захоплення"
    },
    "chatty": {
        "key_translation": "балакучий",
        "value_translation": "любить потеревенити, неформальні розмови"
    },
    "cheerful": {
        "key_translation": "бадьорий",
        "value_translation": "відзначається радісним та оптимістичним настроєм"
    },
    "community-minded": {
        "key_translation": "спрямований на спільноту",
        "value_translation": "цікавиться допомогою громаді; має соціальну свідомість"
    },
    "compassionate": {
        "key_translation": "співчутливий",
        "value_translation": "проявляє співчуття та турботу про страждання або нещастя інших"
    },
    "competitive": {
        "key_translation": "конкурентний",
        "value_translation": "насолоджується та бере участь в змаганні"
    },
    "compliant": {
        "key_translation": "піддатливий",
        "value_translation": "схильний згоджуватись з іншими чи дотримуватись правил"
    },
    "convincing": {
        "key_translation": "переконливий",
        "value_translation": "здатний переконати"
    },
    "cooperative": {
        "key_translation": "співпрацьовитий",
        "value_translation": "залучає взаємодопомогу для досягнення спільної мети"
    },
    "critical": {
        "key_translation": "критичний",
        "value_translation": "аналізує переваги та недоліки завдань"
    },
    "curious": {
        "key_translation": "допитливий",
        "value_translation": "бажає дізнатись або вивчити щось нове"
    },
    "decisive": {
        "key_translation": "рішучий",
        "value_translation": "розв'язує питання; досягає конкретного результату"
    },
    "demanding": {
        "key_translation": "вимогливий",
        "value_translation": "авторитетно вимагає"
    },
    "detail-oriented": {
        "key_translation": "уважний до деталей",
        "value_translation": "звертає увагу на найдрібніші деталі"
    },
    "direct": {
        "key_translation": "прямий",
        "value_translation": "йде прямо до справи; відвертий"
    },
    "disciplined": {
        "key_translation": "дисциплінований",
        "value_translation": "діє з системою правил поведінки"
    },
    "dominant": {
        "key_translation": "панівний",
        "value_translation": "важливий, потужний або впливовий"
    },
    "dramatic": {
        "key_translation": "ефектний",
        "value_translation": "направлений на створення ефекту; театральний"
    },
    "driven": {
        "key_translation": "мотивований",
        "value_translation": "невблаганно змушений потребою досягти мети; дуже працьовитий і амбітний"
    },
    "dynamic": {
        "key_translation": "динамічний",
        "value_translation": "характеризується постійними змінами, активністю або прогресом"
    },
    "easygoing": {
        "key_translation": "легкий",
        "value_translation": "розслаблений та терплячий у способі поведінки"
    },
    "eccentric": {
        "key_translation": "ексцентричний",
        "value_translation": "нетиповий та трохи дивний"
    },
    "empathetic": {
        "key_translation": "емпатичний",
        "value_translation": "показує здатність розуміти та співчувати почуттям іншої людини"
    },
    "energetic": {
        "key_translation": "енергійний",
        "value_translation": "проявляє велику активність або жвавість"
    },
    "engaging": {
        "key_translation": "привабливий",
        "value_translation": "чарівний та привабливий"
    },
    "entertaining": {
        "key_translation": "може розважити",
        "value_translation": "забезпечує розваги або задоволення"
    },
    "enthusiastic": {
        "key_translation": "завзятий",
        "value_translation": "із захопленням, пристрастю віддасться якому-небудь заняттю, якійсь справі; запеклий"
    },
    "even-tempered": {
        "key_translation": "врівноважений",
        "value_translation": "складно роздратувати або розізлити"
    },
    "exciteable": {
        "key_translation": "швидко захоплюється",
        "value_translation": "занадто легко реагує на щось нове або таке, що стимулює"
    },
    "expressive": {
        "key_translation": "експресивний",
        "value_translation": "ефективно передає думки або почуття"
    },
    "extroverted": {
        "key_translation": "екстраверт",
        "value_translation": "контактний та впевнений у суспільних контактах"
    },
    "fearless": {
        "key_translation": "безстрашний",
        "value_translation": "діє без страху"
    },
    "firm": {
        "key_translation": "твердий",
        "value_translation": "сильно відчувається і малоймовірно зміниться"
    },
    "flashy": {
        "key_translation": "яскравий",
        "value_translation": "показово привабливий або такий, що вражає"
    },
    "forceful": {
        "key_translation": "напористий",
        "value_translation": "сильний та наполегливий; виглядає енергійним та потужним"
    },
    "friendly": {
        "key_translation": "дружній",
        "value_translation": "добрий та приємний у спілкуванні"
    },
    "gentle": {
        "key_translation": "ніжний",
        "value_translation": "добрий або тендітний"
    },
    "gregarious": {
        "key_translation": "компанійський",
        "value_translation": "любить компанію; товариський"
    },
    "helpful": {
        "key_translation": "помічний",
        "value_translation": "надає допомогу або готовий її надати"
    },
    "hesitant": {
        "key_translation": "нерішучий",
        "value_translation": "вагається, невпевнений або повільний у вчинках або мові"
    },
    "high-spirited": {
        "key_translation": "піднесений",
        "value_translation": "жвавий та веселий у поведінці або настрої"
    },
    "hospitable": {
        "key_translation": "гостинний",
        "value_translation": "дружній та гостинний до незнайомців або гостей"
    },
    "impatient": {
        "key_translation": "нетерплячий",
        "value_translation": "схильний швидко ображатися або провокуватися"
    },
    "impersonal": {
        "key_translation": "відсторонений",
        "value_translation": "не піддається впливу особистих почуттів, не демонструє їх чи уникає"
    },
    "impulsive": {
        "key_translation": "імпульсивний",
        "value_translation": "діє або робить щось без достатньої передумови"
    },
    "independent": {
        "key_translation": "незалежний",
        "value_translation": "діє самостійно або без значної допомоги"
    },
    "inspiring": {
        "key_translation": "надихаючий",
        "value_translation": "надає натхнення іншим"
    },
    "intense": {
        "key_translation": "інтенсивний",
        "value_translation": "з екстремальною силою, ступенем або міцністю"
    },
    "introverted": {
        "key_translation": "інтровертний",
        "value_translation": "замкнений або суспільно не впевнений"
    },
    "inventive": {
        "key_translation": "винахідливий",
        "value_translation": "творчий у процесі або результаті"
    },
    "inviting": {
        "key_translation": "запрошуючий",
        "value_translation": "обіцяє привабливий або приємний досвід"
    },
    "joyful": {
        "key_translation": "радісний",
        "value_translation": "відчуває, виражає або спричиняє велике задоволення та щастя"
    },
    "kind": {
        "key_translation": "добрий",
        "value_translation": "має або виявляє приязний, щедрий та уважний характер"
    },
    "lenient": {
        "key_translation": "поблажливий",
        "value_translation": "дозволяє, милосердний або терплячий"
    },
    "light-hearted": {
        "key_translation": "безтурботний",
        "value_translation": "веселий та безтурботний"
    },
    "lively": {
        "key_translation": "жвавий",
        "value_translation": "повний життя та енергії, активний та комунікабельний"
    },
    "logical": {
        "key_translation": "логічний",
        "value_translation": "відповідно до правил логіки або формального доведення"
    },
    "loyal": {
        "key_translation": "вірний",
        "value_translation": "дає або показує тверду та постійну підтримку або присягу"
    },
    "magnetic": {
        "key_translation": "притягуючий",
        "value_translation": "дуже привабливий або спокусливий"
    },
    "matter-of-fact": {
        "key_translation": "фактичний",
        "value_translation": "беземоційний та практичний"
    },
    "methodical": {
        "key_translation": "методичний",
        "value_translation": "діє відповідно до систематичної або встановленої процедури"
    },
    "meticulous": {
        "key_translation": "педантичний",
        "value_translation": "показує велику увагу до деталей, дуже обережний та точний"
    },
    "mild-mannered": {
        "key_translation": "лагідний",
        "value_translation": "ніжний і не схильний до емоційних крайнощів"
    },
    "modest": {
        "key_translation": "скромний",
        "value_translation": "скромний або помірний у оцінці своїх можливостей та досягнень"
    },
    "obedient": {
        "key_translation": "покірний",
        "value_translation": "підкоряється або готовий підкорятися наказам чи проханням"
    },
    "objective": {
        "key_translation": "об'єктивний",
        "value_translation": "не керується особистими почуттями або думками при врахуванні та представленні фактів"
    },
    "observant": {
        "key_translation": "спостережливий",
        "value_translation": "швидко помічає речі"
    },
    "open": {
        "key_translation": "відкритий",
        "value_translation": "відкритий у вираженні думок та почуттів"
    },
    "optimistic": {
        "key_translation": "оптимістичний",
        "value_translation": "сподівається та вірить в краще майбутнє"
    },
    "organized": {
        "key_translation": "організований",
        "value_translation": "тримає справи у порядку для їх ефективного вирішення"
    },
    "outspoken": {
        "key_translation": "відкритий у висловленні думок",
        "value_translation": "відверто висловлює свої думки"
    },
    "outgoing": {
        "key_translation": "комунікабельний",
        "value_translation": "дружній та легко контактує з іншими людьми"
    },
    "passionate": {
        "key_translation": "пристрасний",
        "value_translation": "показує сильні почуття чи переконання"
    },
    "patient": {
        "key_translation": "терплячий",
        "value_translation": "здатний приймати або терпіти затримки або проблеми"
    },
    "peaceful": {
        "key_translation": "миролюбний",
        "value_translation": "вільний від збудження; спокійний"
    },
    "perceptive": {
        "key_translation": "проникливий",
        "value_translation": "має чутливе сприйняття або розуміння"
    },
    "perfectionist": {
        "key_translation": "перфекціоніст",
        "value_translation": "відмовляється від прийняття стандарту, що є нижчим за ідеальний"
    },
    "persistent": {
        "key_translation": "наполегливий",
        "value_translation": "продовжує діяти наполегливо або цілеспрямовано"
    },
    "persuasive": {
        "key_translation": "переконливий",
        "value_translation": "ефективно переконує інших"
    },
    "playful": {
        "key_translation": "жартівливий",
        "value_translation": "грайливий; життєрадісний"
    },
    "positive": {
        "key_translation": "позитивний",
        "value_translation": "конструктивний, оптимістичний або впевнений"
    },
    "practical": {
        "key_translation": "практичний",
        "value_translation": "зацікавлений у виконанні завдань, а не в теорії або ідеях"
    },
    "precise": {
        "key_translation": "точний",
        "value_translation": "точний, точний та дбайливий про деталі"
    },
    "private": {
        "key_translation": "приватний",
        "value_translation": "обирає не ділитися своїми думками та почуттями з іншими"
    },
    "prudent": {
        "key_translation": "розважливий",
        "value_translation": "діє з думкою про майбутнє"
    },
    "refined": {
        "key_translation": "витончений",
        "value_translation": "елегантний та культурний у зовнішньому вигляді, манері або смаку"
    },
    "relaxed": {
        "key_translation": "розслаблений",
        "value_translation": "вільний від напруження та тривоги; спокійний"
    },
    "relentless": {
        "key_translation": "невтомний",
        "value_translation": "ніколи не здається"
    },
    "reserved": {
        "key_translation": "закритий",
        "value_translation": "повільно виявляє емоції та думки"
    },
    "restrained": {
        "key_translation": "стриманий",
        "value_translation": "характеризується здержаністю або поміркованістю; невиразний або безпристрасний"
    },
    "restless": {
        "key_translation": "невгамовний",
        "value_translation": "характеризується постійною активністю або рухом"
    },
    "routine": {
        "key_translation": "рутинний",
        "value_translation": "діє у сталий, передбачуваний спосіб"
    },
    "satisfied": {
        "key_translation": "задоволений",
        "value_translation": "задоволений або радісний"
    },
    "secretive": {
        "key_translation": "таємничий",
        "value_translation": "схильний приховувати свої почуття та наміри або не розголошувати інформацію"
    },
    "self-sufficient": {
        "key_translation": "самодостатній",
        "value_translation": "не потребує зовнішньої допомоги в задоволенні своїх базових потреб"
    },
    "sensitive": {
        "key_translation": "чутливий",
        "value_translation": "має або показує швидкий та чутливий інстинкт сприйняття почуттів інших людей"
    },
    "serious": {
        "key_translation": "серйозний",
        "value_translation": "діє або говорить щиро та серйозно"
    },
    "skeptical": {
        "key_translation": "скептичний",
        "value_translation": "важко переконується; має сумніви"
    },
    "sociable": {
        "key_translation": "товариський",
        "value_translation": "бажає розмовляти та займатися діяльністю з іншими людьми"
    },
    "social": {
        "key_translation": "соціальний",
        "value_translation": "привабливий і дружній",
    },
    "soft-spoken": {
        "key_translation": "виважений",
        "value_translation": "говорить тихим, спокійним голосом"
    },
    "steady": {
        "key_translation": "стійкий",
        "value_translation": "міцно закріплений, підтриманий або збалансований; не тремтить або не рухається"
    },
    "straight-shooter": {
        "key_translation": "правдоруб",
        "value_translation": "чесна і пряма людина"
    },
    "stubborn": {
        "key_translation": "упертий",
        "value_translation": "має або показує непохитну вольову вдачу, не змінює своїх поглядів або позиції щодо чогось"
    },
    "supporting": {
        "key_translation": "підтримуючий",
        "value_translation": "допоміжний до інших"
    },
    "sympathetic": {
        "key_translation": "доброзичливий",
        "value_translation": "має, показує або виражає повагу до емоцій інших людей"
    },
    "systematic": {
        "key_translation": "систематичний",
        "value_translation": "діє за фіксованим планом або системою"
    },
    "tactful": {
        "key_translation": "тактовний",
        "value_translation": "має або показує такт"
    },
    "thoughtful": {
        "key_translation": "уважний до інших",
        "value_translation": "проявляє увагу до потреб інших людей"
    },
    "tough": {
        "key_translation": "міцний",
        "value_translation": "здатний терпіти тягарі, витримувати випробування"
    },
    "traditional": {
        "key_translation": "традиційний",
        "value_translation": "перебуває під сильним впливом минулих подій"
    },
    "uncompromising": {
        "key_translation": "безкомпромісний",
        "value_translation": "має або показує непохитну вольову вдачу, не погоджується з іншими"
    },
    "unsentimental": {
        "key_translation": "несентиментальний",
        "value_translation": "не піддається впливу почуттів"
    },
    "vigorous": {
        "key_translation": "енергійний",
        "value_translation": "сильний, здоровий і повний енергії"
    },
    "warm": {
        "key_translation": "палкий",
        "value_translation": "має, показує або виражає ентузіазм, пристрасть або любов до людей"
    }
};

export const discTypes = {
    "D":  "Люди з типом особистості D (капітан), як правило, наполегливі, сильні та амбітні. Зазвичай вони прагматичні, орієнтовані на результат, працюють швидко та приймають рішення твердо й об’єктивно. З позицією у верхньому лівому куті DISC, капітани віддають перевагу значній незалежності та можуть виснажуватись, коли інші очікують від них більшої співпраці. Вони також можуть віддавати перевагу контролю над ситуацією, а не реагувати на інших.",
    "Di": "Люди з типом особистості Di (керівник) зазвичай наполегливі, здатні сміливо висувати свої позиції та стійкі до впливу інших. Переконуючи інших працювати для досягнення своїх цілей, вони можуть вважатися рішучими, сильними та переконливими",
    "DI": "Люди з типом особистості DI (ініціатор), як правило, підходять до людей і ситуацій енергійно, жваво. Їм, ймовірно, сподобається знайомство з новими людьми та завоювання їх сильними соціальними навичками та вмінням бути переконливими",
    "Id": "Люди з типом особистості Id (інфлюенсер), як правило, енергійні та схильні до пригод, спілкуються невимушеною мовою, сміливими заявами та зосереджені на загальній картині. Ймовірно, вони мають легку, розслаблену та невимушену манеру розмовляти чи взаємодіяти з інші та насолоджуватися викликом знайомства з новими людьми.",
    "I":  "Люди з типом особистості I (мотиватор), як правило, сповнені ентузіазму, життєрадісності та комунікабельності. Вони зазвичай легко, розслаблено, невимушено говорять або спілкуються з іншими",
    "Is": "Люди з типом особистості Is (натхненник) зазвичай теплі, життєрадісні та безтурботні. Оскільки вони, як правило, позитивні та радісні, натхненники, ймовірно, знайдуть в інших багато чого, що можна цінувати",
    "IS": "Люди з типом особистості IS (гармонізатор), як правило, теплі, врівноважені, толерантні. Оскільки вони, як правило, позитивні та радісні, гармонізатори, швидше за все, шукатимуть багато соціальної взаємодії та ствердження",
    "Si": "Люди з типом особистості Si (радник) схильні бути врівноваженими та доброзичливими. Будучи сповненими співчуття та підтримки, вони можуть прагнути допомагати іншим, часто та щиро",
    "S":  "Люди з типом особистості S (той, хто підтримує) схильні бути спокійними, терплячими та шанобливими у взаємодії з іншими. Рідко гніваються чи збуджуються, вони, швидше за все, працюватимуть, щоб підтримувати мирне та гармонійне середовище",
    "Sc": "Люди з типом особистості Sc (Планувальник), як правило, легкі на підйом і досить врівноважені за своїм темпераментом. Вони часто забезпечують передбачуваність і послідовність у своїх взаємодіях і прагнуть того ж у відповідь",
    "SC": "Люди з типом особистості SC (Стабілізатор) схильні бути врівноваженими, досить стриманими та обережними під час прийняття рішень. Стабілізатори зазвичай пропонують іншим передбачуваність і послідовність у взаємодії та, ймовірно, бажають такої ж відповіді від інших",
    "Cs": "Люди з типом особистості Cs (Редактор), як правило, більш стримані та самотні. Редактори зазвичай обмежують свої контакти з іншими до стосунків, які є більш структурованими, ніж неформальними, тому вони можуть відчувати себе не впевнено в компанії та не брати активну участь у розмовах",
    "C":  "Люди з типом особистості С (Аналітик) схильні бути об’єктивними, скептичними та логічними у своїй поведінці. Зазвичай вони надзвичайно прагматичні та часто розв'язують проблеми за допомогою аналітичного підходу, орієнтованого на факти. Вони, швидше за все, більш стримані в групах і може пройти багато часу, перш ніж вони довіряться достатньо, щоб відкритися іншим.",
    "Cd": "Люди з типом особистості Cd (Скептик), як правило, більш серйозні у своїй поведінці та дуже логічні у своєму мисленні. Їм, як правило, найбільше подобається вдумлива, незалежна робота, і вони можуть ставитися до інших у більш віддалений та відсторонений спосіб.",
    "CD": "Люди з типом особистості CD (Допитувач) намагаються якомога більше уникати емоцій у процесі прийняття рішень, цінуючи ефективність і логіку над інтуїцією чи колективною думкою. Вони зазвичай більш стримані у своїх взаємодіях і ставляться до інших більш віддалено та відсторонено, повільно будуючи довіру.",
    "Dc": "Люди з типом особистості Dc (Архітектор), як правило, більш напористі та використовують більш рішучий підхід до життя, ніж більшість людей. Вони вольові та незалежні, як правило, віддають перевагу йти власним шляхом і керувати своєю діяльністю, а не активно співпрацювати з іншими"
};