import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  loginButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const UnauthorizedPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const classes = useStyles();

  if (!isAuthenticated) {
    return (
      <Container className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Будь ласка, увійдіть щоб отримати доступ до сайту
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.loginButton}
          onClick={() => loginWithRedirect()}
          startIcon={<LockOpenIcon />}
        >
          Вхід
        </Button>
      </Container>
    );
  }

  // Render the authorized content when the user is authenticated
  // ...
};

export default UnauthorizedPage;
