import React, { useState } from "react";
import { Tooltip, Typography, Container, Button, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { discTypes, questionData, translations } from './consts'
import "./App.css";
import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';
import UnauthorizedPage from './UnauthorizedPage';



const useStyles = makeStyles(() => ({
  greenSelected: {
    "&.MuiToggleButton-root": {
      "&.Mui-selected": {
        backgroundColor: "#4caf50",
        color: "#ffffff",
        "&:hover": {
          backgroundColor: "#388e3c",
        },
      },
    },
  },
  redSelected: {
    "&.MuiToggleButton-root": {
      "&.Mui-selected": {
        backgroundColor: "#f44336",
        color: "#ffffff",
        "&:hover": {
          backgroundColor: "#d32f2f",
        },
      },
    },
  },
}));

const apiUrl = "https://muddy-flower-e54d.gross-otaman7616.workers.dev/";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState([]);
  const [mostOption, setMostOption] = useState(null);
  const [leastOption, setLeastOption] = useState(null);
  const [apiResult, setApiResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { isAuthenticated, user } = useAuth0();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [clickedCellIndex, setClickedCellIndex] = useState(null);


  if (!isAuthenticated) {
    return <UnauthorizedPage />;
  }

  const handleMostChange = (event) => {
    setMostOption(event.target.value);
  };

  const handleLeastChange = (event) => {
    setLeastOption(event.target.value);
  };

  const handleNext = () => {
    setResponses((prevResponses) => [
      ...prevResponses,
      { most_or_least: "most", option_id: mostOption, question_id: questionData.data[currentQuestion].id },
      { most_or_least: "least", option_id: leastOption, question_id: questionData.data[currentQuestion].id },
    ]);
    setCurrentQuestion(currentQuestion + 1);
    setMostOption(null);
    setLeastOption(null);
  };

  const handleBack = () => {
    setCurrentQuestion(currentQuestion - 1);
    setResponses(responses.slice(0, -2));
  };

  const handleSubmit = async () => {
    const updatedResponses = [
      ...responses,
      { most_or_least: "most", option_id: mostOption, question_id: questionData.data[currentQuestion].id },
      { most_or_least: "least", option_id: leastOption, question_id: questionData.data[currentQuestion].id },
    ];

    setLoading(true);
    try {
      const response = await axios.post(apiUrl, {
        responses: updatedResponses,
        user: user
      });
      setApiResult(response.data);
    } catch (error) {
      setApiResult({ error: true, message: error.message });
    }
    setLoading(false);
  };

  const processResults = (e) => {
    var t = ["Is", "I", "Id", "DI", "Di", "D", "Dc", "CD", "Cd", "C", "Cs", "SC", "Sc", "S", "Si"]
      , n = 11.25;
    return t.find((function (r, s) {
      var o = n + 22.5;
      if (e > n && e <= o)
        return t[s];
      n = o
    }
    )) || "IS"
  }

  if (apiResult) {
    const type = processResults(apiResult.data?.degrees);
    const moreUrl = `https://www.crystalknows.com/disc/${type}-personality-type`;
    return (
      <div className="App">
        <Container maxWidth="md">
          {apiResult.error ? (
            <div>
              <Typography variant="h3" align="center" gutterBottom>
                Помилка
              </Typography>
              <Typography variant="body1" align="center">
                {apiResult.message}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h3" align="center" gutterBottom>
                Ваш тип особистості: {type || "Невідомо"}
              </Typography>
              {apiResult?.data?.degrees && (
                <>
                  <Typography variant="body1" align="center">
                    {discTypes[type]}
                  </Typography>
                  <Typography>
                    <Button href={moreUrl}>Більше тут</Button>
                  </Typography>
                </>
              )}
            </div>
          )}
        </Container>
      </div>
    );

  }

  const question = questionData.data[currentQuestion];

  return (
    <div className="App">
      <Container maxWidth="md">
        <h2>{translations["welcome_message"]}</h2>
        <h3>Запитання #{currentQuestion + 1} з {questionData.data.length}</h3>

        <>
          {isMobile ? (
            <form>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>👍👍</TableCell>
                    <TableCell>👎👎</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {question.options.map((option) => {
                    const displayText = translations[option.text]?.key_translation || option.text;
                    const description = translations[option.text]?.value_translation;
                    return (
                      <React.Fragment key={option.id}>
                        <TableRow>
                          <TableCell onClick={() => setClickedCellIndex(clickedCellIndex === option.id ? null : option.id)}>
                            {displayText}
                            {description && (
                              <>
                                {` `}
                                <span
                                  className="material-icons"
                                >
                                  info
                                </span>
                                {clickedCellIndex === option.id && (
                                  <Typography variant="body2" color="textSecondary">
                                    {`🇬🇧${option.text}; ${description}`}
                                  </Typography>
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            <ToggleButtonGroup
                              size="large"
                              value={mostOption === option.id ? 'most' : ''}
                              exclusive
                              onChange={(event, value) => {
                                if (value === 'most') {
                                  handleMostChange({ target: { value: option.id } });
                                }
                              }}
                            >
                              <ToggleButton value="most" classes={{ root: classes.greenSelected }} />
                            </ToggleButtonGroup>
                          </TableCell>
                          <TableCell>
                            <ToggleButtonGroup
                              size="large"
                              value={leastOption === option.id ? 'least' : ''}
                              exclusive
                              onChange={(event, value) => {
                                if (value === 'least') {
                                  handleLeastChange({ target: { value: option.id } });
                                }
                              }}
                            >
                              <ToggleButton value="least" classes={{ root: classes.redSelected }} />
                            </ToggleButtonGroup>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </form>
          ) : (
            <form>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {question.options.map((option) => {
                      const displayText = translations[option.text]?.key_translation || option.text;
                      const description = translations[option.text]?.value_translation;

                      return (
                        <TableCell key={option.id}>
                          {displayText}
                          {displayText && (
                            <>
                              <br />
                              <span>{option.text} </span>
                            </>
                          )}
                          {description && (
                            <Tooltip title={description}>
                              <span className="material-icons">info</span>
                            </Tooltip>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Дуже я</TableCell>
                    {question.options.map((option) => (
                      <TableCell key={option.id}>
                        <ToggleButtonGroup
                          size="large"
                          value={mostOption === option.id ? 'most' : ''}
                          exclusive
                          onChange={(event, value) => {
                            if (value === 'most') {
                              handleMostChange({ target: { value: option.id } });
                            }
                          }}
                        >
                          <ToggleButton value="most" classes={{ root: classes.greenSelected }} />
                        </ToggleButtonGroup>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Взагалі не я</TableCell>
                    {question.options.map((option) => (
                      <TableCell key={option.id}>
                        <ToggleButtonGroup
                          size="large"
                          value={leastOption === option.id ? 'least' : ''}
                          exclusive
                          onChange={(event, value) => {
                            if (value === 'least') {
                              handleLeastChange({ target: { value: option.id } });
                            }
                          }}
                        >
                          <ToggleButton value="least" classes={{ root: classes.redSelected }} />
                        </ToggleButtonGroup>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </form>
          )}
        </>




        {currentQuestion > 0 && (
          <Button variant="outlined" color="primary" onClick={handleBack}>
            Назад
          </Button>
        )}
        {currentQuestion < questionData.data.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!mostOption || !leastOption || mostOption === leastOption}
            onClick={handleNext}
          >
            Далі
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={!mostOption || !leastOption || mostOption === leastOption || loading}
            onClick={handleSubmit}
          >
            {loading ? "Обробка..." : "Готово"}
          </Button>
        )}
      </Container>
    </div>
  );
}

export default App;
